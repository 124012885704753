import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UrlsService} from './urls.service';

@Injectable()
export class UserService {
  loginUser = [];
  constructor(private http:HttpClient, private urlsService:UrlsService) {
  }

  checkUserSession() {
    let url = this.urlsService.urls().user_checksession
    return this.http.get<any>(url);
  }

  login(userName:string, password:string) {
    return this.http.post<any>(`${this.urlsService.urls().user_login}`,
      `username=${userName}&password=${password}`,
      {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})});
  }

  logout() {
    return this.http.post<any>(this.urlsService.urls().user_logout, {});
  }
}
