import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(private router: Router) {
  }

  hideLayout = false;
  sub: any;
  url: String;

  ngOnInit() {
    this.sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        console.log(this.url);
        if (this.url.match(/^\/login/)) {
          this.hideLayout = true;

        } else {
          this.hideLayout = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
