import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd';
import {UrlsService} from "../services/urls.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FormBuilder, Validators} from "@angular/forms";
import {UserService} from "../services/user.service";
import { UploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  nzData = [];
  classList = [];
  selectedSchool = '';
  nzTotal = 0;
  nzPageIndex = 1;
  nzPageSize = 20;
  nzPageSizeOptions = [20, 30, 40, 50];
  nzLoading = false;

  drawerVisible = false;
  isSendingForm = false;

  isPreviewingForm = false;
  previewVisible = false;
  currentOrgPrefix = '';
  orgList = [];
  genders = [{"k":0,"v":"Select"}, {"k":1,"v":"Male"}, {"k":2,"v":"Female"}];
  roles = [{"roleId":1,"roleName":"Student"}, {"roleId":2,"roleName":"Teacher"}, {"roleId":3,"roleName":"SchoolOffice"}, {"roleId":4,"roleName":"SchoolAdmin"}];
  geolocation= [
    ["CN", "China"],
    ["HK", "Hongkong"],
    ["TW", "Taiwan"],
    ["KP", "North Korea"],
    ["CA", "Canada"],
    ["AO", "Angola"],
    ["AF", "Afghanistan"],
    ["AL", "Albania"],
    ["DZ", "Algeria"],
    ["AD", "Andorra"],
    ["AI", "Anguilla"],
    ["AG", "Barbuda Antigua"],
    ["AR", "Argentina"],
    ["AM", "Armenia"],
    ["AU", "Australia"],
    ["AT", "Austria"],
    ["AZ", "Azerbaijan"],
    ["BS", "Bahamas"],
    ["BH", "Bahrain"],
    ["BD", "Bangladesh"],
    ["BB", "Barbados"],
    ["BY", "Belarus"],
    ["BE", "Belgium"],
    ["BZ", "Belize"],
    ["BJ", "Benin"],
    ["BM", "Bermuda Is."],
    ["BO", "Bolivia"],
    ["BW", "Botswana"],
    ["BR", "Brazil"],
    ["BN", "Brunei"],
    ["BG", "Bulgaria"],
    ["BF", "Burkina-faso"],
    ["MM", "Burma"],
    ["BI", "Burundi"],
    ["CM", "Cameroon"],
    ["CF", "Central African Republic"],
    ["TD", "Chad"],
    ["CL", "Chile"],
    ["CO", "Colombia"],
    ["CG", "Congo"],
    ["CK", "Cook Is."],
    ["CR", "Costa Rica"],
    ["CU", "Cuba"],
    ["CY", "Cyprus"],
    ["CZ", "Czech Republic"],
    ["DK", "Denmark"],
    ["DJ", "Djibouti"],
    ["DO", "Dominica Rep."],
    ["EC", "Ecuador"],
    ["EG", "Egypt"],
    ["SV", "EI Salvador"],
    ["EE", "Estonia"],
    ["ET", "Ethiopia"],
    ["FJ", "Fiji"],
    ["FI", "Finland"],
    ["FR", "France"],
    ["GF", "French Guiana"],
    ["GA", "Gabon"],
    ["GM", "Gambia"],
    ["GE", "Georgia"],
    ["DE", "Germany"],
    ["GH", "Ghana"],
    ["GI", "Gibraltar"],
    ["GR", "Greece"],
    ["GD", "Grenada"],
    ["GU", "Guam"],
    ["GT", "Guatemala"],
    ["GN", "Guinea"],
    ["GY", "Guyana"],
    ["HT", "Haiti"],
    ["HN", "Honduras"],
    ["HU", "Hungary"],
    ["IS", "Iceland"],
    ["IN", "India"],
    ["ID", "Indonesia"],
    ["IR", "Iran"],
    ["IQ", "Iraq"],
    ["IE", "Ireland"],
    ["IL", "Israel"],
    ["IT", "Italy"],
    ["JM", "Jamaica"],
    ["JP", "Japan"],
    ["JO", "Jordan"],
    ["KH", "Kampuchea (Cambodia )"],
    ["KZ", "Kazakstan"],
    ["KE", "Kenya"],
    ["KR", "Korea"],
    ["KW", "Kuwait"],
    ["KG", "Kyrgyzstan"],
    ["LA", "Laos"],
    ["LV", "Latvia"],
    ["LB", "Lebanon"],
    ["LS", "Lesotho"],
    ["LR", "Liberia"],
    ["LY", "Libya"],
    ["LI", "Liechtenstein"],
    ["LT", "Lithuania"],
    ["LU", "Luxembourg"],
    ["MO", "Macao"],
    ["MG", "Madagascar"],
    ["MW", "Malawi"],
    ["MY", "Malaysia"],
    ["MV", "Maldives"],
    ["ML", "Mali"],
    ["MT", "Malta"],
    ["MU", "Mauritius"],
    ["MX", "Mexico"],
    ["MD", "Moldova"],
    ["MC", "Monaco"],
    ["MN", "Mongolia"],
    ["MS", "Montserrat Is."],
    ["MA", "Morocco"],
    ["MZ", "Mozambique"],
    ["NA", "Namibia"],
    ["NR", "Nauru"],
    ["NP", "Nepal"],
    ["NL", "Netherlands"],
    ["NZ", "New Zealand"],
    ["NI", "Nicaragua"],
    ["NE", "Niger"],
    ["NG", "Nigeria"],
    ["NO", "Norway"],
    ["OM", "Oman"],
    ["PK", "Pakistan"],
    ["PA", "Panama"],
    ["PG", "Papua New Cuinea"],
    ["PY", "Paraguay"],
    ["PE", "Peru"],
    ["PH", "Philippines"],
    ["PL", "Poland"],
    ["PF", "French Polynesia"],
    ["PT", "Portugal"],
    ["PR", "Puerto Rico"],
    ["QA", "Qatar"],
    ["RO", "Romania"],
    ["RU", "Russia"],
    ["LC", "Saint Lueia"],
    ["VC", "Saint Vincent"],
    ["SM", "San Marino"],
    ["ST", "Sao Tome and Principe"],
    ["SA", "Saudi Arabia"],
    ["SN", "Senegal"],
    ["SC", "Seychelles"],
    ["SL", "Sierra Leone"],
    ["SG", "Singapore"],
    ["SK", "Slovakia"],
    ["SI", "Slovenia"],
    ["SB", "Solomon Is."],
    ["SO", "Somali"],
    ["ZA", "South Africa"],
    ["ES", "Spain"],
    ["LK", "Sri Lanka"],
    ["SD", "Sudan"],
    ["SR", "Suriname"],
    ["SZ", "Swaziland"],
    ["SE", "Sweden"],
    ["CH", "Switzerland"],
    ["SY", "Syria"],
    ["TJ", "Tajikstan"],
    ["TZ", "Tanzania"],
    ["TH", "Thailand"],
    ["TG", "Togo"],
    ["TO", "Tonga"],
    ["TT", "Trinidad and Tobago"],
    ["TN", "Tunisia"],
    ["TR", "Turkey"],
    ["TM", "Turkmenistan"],
    ["UG", "Uganda"],
    ["UA", "Ukraine"],
    ["AE", "United Arab Emirates"],
    ["GB", "United Kiongdom"],
    ["US", "United States of America"],
    ["UY", "Uruguay"],
    ["UZ", "Uzbekistan"],
    ["VE", "Venezuela"],
    ["VN", "Vietnam"],
    ["YE", "Yemen"],
    ["YU", "Yugoslavia"],
    ["ZW", "Zimbabwe"],
    ["ZR", "Zaire"],
    ["ZM", "Zambia"]
  ];
  userForm = this.fb.group({
    userId: [''],
    orgId:[0],
    tutorSchedule:[false],
    username: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(10)]],
    password: ['', [Validators.required,Validators.maxLength(30)]],
    roleId: ['0', [Validators.required]],
    surname: ['', [Validators.required]],
    givenName:['', [Validators.required]],
    gender:['0', [Validators.required]],
    city:['', [Validators.required]],
    countryId:[0, [Validators.required]],
    email:['', [Validators.required]],
    phone:[''],
    description:[''],
  });


  constructor(private router: Router,
              private route: ActivatedRoute,
              private message: NzMessageService,
              private urlsService: UrlsService,
              private userService: UserService,
              private fb: FormBuilder,
              private http: HttpClient) {

  }
  ngOnInit() {
    this.getOrgList();
    this.search();
  }

  research() {
    this.search();
  }

  nzPageIndexChange(pageIndex: number) {
    this.nzPageIndex = pageIndex;
    this.search();
  }

  nzPageSizeChange(pageSize: number) {
    this.nzPageIndex = 1;
    this.nzPageSize = pageSize;
    this.search();
  }

  getOrgList():void {
    this.http.get<any>(this.urlsService.urls().org_list).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.orgList = res.data.list;
        }
        }
    });
  }

  search(): void {
    this.nzLoading = true;
    this.http.get<any>(this.urlsService.urls().user_list  +
      `?orgId=` + this.selectedSchool + `&page=${this.nzPageIndex}&size=${this.nzPageSize}`).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          for(var i=0;i< res.data.list.length; i++){
              var roleInfo = this.getRole(res.data.list[i].roleId);
              if(roleInfo) {
                res.data.list[i].roleName = roleInfo["roleName"];
              }
             res.data.list[i].genderTitle = this.genders[res.data.list[i].gender].v;
          }
          this.nzData = res.data.list;
          this.nzTotal = res.data.total;
        }
      }

      this.nzLoading = false;
    });
  }

  open() {
    this.userForm.reset();
    this.currentOrgPrefix = this.userService.loginUser['orgInfo'].prefix;
    this.isSendingForm = false;
    this.drawerVisible = true;
  }

  close() {
    this.drawerVisible = false;
  }
  submitForm($event: any, value: any): void {
    $event.preventDefault();
    for (const key in this.userForm.controls) {
      this.userForm.controls[key].markAsDirty();
      this.userForm.controls[key].updateValueAndValidity();
    }
    if (this.isSendingForm) return;
    this.isSendingForm = true;
    if(value.userId == null) {
      value.userId = "";
    } else {
      value.userId += "";
    }
    if(value.orgId != "") {
      value.orgId*=1;
    } else {
      value.orgId = 0;
    }
    value.roleId *= 1;
    value.gender*=1;
    value.status*=1;
    if(value.tutorSchedule == false) {
      value.tutorSchedule = 0;
    } else {
      value.tutorSchedule = 1;
    }
    this.http.post(this.urlsService.urls().user_create, value).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'Successful');
        this.close();
        this.search();
      } else {
        this.message.create('error', `Failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `Failure：${err}`);
    }, () => {
      this.isSendingForm = false;
    });
  }
  getRole(roleId: any): any {
    for(var i=0;i<this.roles.length;i++) {
      var roleInfo = this.roles[i];
      if(roleInfo["roleId"] == roleId) {
        return roleInfo;
      }
    }
    return;
  }
  update(data: any): void {
    this.open();
    data.gender += '';
    data.roleId += '';
    data.userId = data.id;
    if(data.tutorSchedule == 0) {
      data.tutorSchedule = false;
    } else {
      data.tutorSchedule = true;
    }
    let updateFormData = Object.assign({}, data);
    this.userForm.patchValue(updateFormData);
  }
  delete(userId: any):void {
    if(!confirm('confirm delete user ?')) {
      return;
    }
    if(this.isSendingForm == true) {
      return;
    }
    this.isSendingForm = true;
    this.http.post(this.urlsService.urls().user_delete + '/' + userId, null).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'successful');
        this.search();
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {
      this.isSendingForm = false;
    });
  }

  handleChange(info: UploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.message.error(`${info.file.name} file upload failed.`);
    }
  }

  getOrgTitle(orgId:any):any {
    for(var i=0;i<this.orgList.length;i++) {
      if(this.orgList[i].orgId == orgId) {
        return this.orgList[i].title;
      }
    }
    return '';
  }

  orgChanged(orgId:any):any {
    for(var i=0;i<this.orgList.length;i++) {
      if(this.orgList[i].id == orgId) {
        this.currentOrgPrefix = this.orgList[i].prefix;
      }
    }
    console.log(orgId,this.currentOrgPrefix);
  }

  login(userId:any):any {
    this.http.post(this.urlsService.urls().user_change + '/' + userId, null).subscribe((res: any) => {
      if (res.errno === 200) {
        this.userService.loginUser = res.data;
        location.href = location.href.split('#')[0];
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {
    });
  }

  clear() {

  }
}
