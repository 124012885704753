import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from './user.service';
import {Statics} from '../global/statics';
import {Subject} from 'rxjs/internal/Subject';


@Injectable()
export class SystemSessionService implements CanActivate {
  constructor(private userService:UserService,
              private router:Router) {
  }

  private emitter:Subject<any> = new Subject<any>();
  private userInfo:any = null;
  private WITHOUT_ROUTING = false;

  canActivate():Promise<boolean> {
    return this.checkSession(this.WITHOUT_ROUTING);
  }

  checkSession(withoutRouting:boolean):Promise<boolean> {
    return new Promise((resolve) => {
      this.userService.checkUserSession().subscribe(res => {
        if (res.errno === 200) {
          this.userService.loginUser = res.data;
          resolve(true);
        } else {
          if (!withoutRouting) {
            this.router.navigateByUrl(Statics.NO_SESSION_PAGE);
          }
          this.userInfo = null;
          this.emitter.next(this.userInfo);
          resolve(false);
        }
      }, () => {
        if (!withoutRouting) {
          this.router.navigateByUrl(Statics.NO_SESSION_PAGE);
        }
        this.userInfo = null;
        this.emitter.next(this.userInfo);
        resolve(false);
      });
    });
  }

  getEmitter() {
    return this.emitter;
  }
}
