export class Statics {
  public static SESSION_PAGE = '/index';
  public static NO_SESSION_PAGE = '/login';

  public static MENU_KEYS = {
    LANGUAGE: 'language',
    BIBLE: 'bible'
  };

  public static PROCESS_STATUS = {
    DRAFT: 0,
    PUBLISHED: 1,
    EDITED: 2,
    UNPUBLISHED: 3
  };

  public static PAYMENT_STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    FAILED: 2,
    CANCEL: 3,
    EXCEPTION: 4
  };

  public static ORDER_STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    REFUND: 2,
    CANCEL: 3,
    NOPAYMENTREQUIRE: 4,
    FORFREE: 5
  }

  public static PAYMENT_METHOD = {
    PAYPAL: "PAYPAL",
    PAYDOLLAR: "PAYDOLLAR",
    ALIPAY: "ALIPAY",
    WECHATONL: "WECHATONL"
  };
}
