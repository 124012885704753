import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd';
import {UrlsService} from "../services/urls.service";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-org',
  templateUrl: './org.component.html',
  styleUrls: ['./org.component.css']
})
export class OrgComponent implements OnInit {
  nzData = [];
  nzTotal = 0;
  nzPageIndex = 1;
  nzPageSize = 20;
  nzPageSizeOptions = [20, 30, 40, 50];
  nzLoading = false;
  selectedOrgId = 0;
  ImproveLimit = 0;
  drawerVisible = false;
  isSendingForm = false;

  improveLimitModalVisible = false;

  previewVisible = false;
  courseCategorys = [];

  orgForm = this.fb.group({
    orgId: [''],
    trialDays:[0, [Validators.required,Validators.min(1)]],
    prefix: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(10)]],
    title: ['', [Validators.required,Validators.maxLength(60)]],
    categoryId:[0],
    description:[''],
  });


  constructor(private router: Router,
              private route: ActivatedRoute,
              private message: NzMessageService,
              private urlsService: UrlsService,
              private fb: FormBuilder,
              private http: HttpClient) {

  }
  ngOnInit() {
    this.getCourseCategorys();
    this.search();
  }

  research() {
    this.search();
  }

  nzPageIndexChange(pageIndex: number) {
    this.nzPageIndex = pageIndex;
    this.search();
  }

  nzPageSizeChange(pageSize: number) {
    this.nzPageIndex = 1;
    this.nzPageSize = pageSize;
    this.search();
  }

  search(): void {
    this.nzLoading = true;
    this.http.get<any>(this.urlsService.urls().org_list  +
      `?page=${this.nzPageIndex}&size=${this.nzPageSize}`).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.nzData = res.data.list;
          this.nzTotal = res.data.total;
        }
      }

      this.nzLoading = false;
    });
  }

  getCourseCategorys():void {
    this.http.get<any>(this.urlsService.urls().course_categories).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.courseCategorys = res.data;
        }
      }
    });
  }

  open() {
    this.orgForm.reset();
    this.orgForm.controls['trialDays'].setValue(30);
    this.isSendingForm = false;
    this.drawerVisible = true;
  }

  close() {
    this.drawerVisible = false;
  }
  submitForm($event: any, value: any): void {
    $event.preventDefault();
    value.categoryId = value.categoryId*1;
    for (const key in this.orgForm.controls) {
      this.orgForm.controls[key].markAsDirty();
      this.orgForm.controls[key].updateValueAndValidity();
    }
    if(value.categoryId == 0) {
      this.message.create('warning', 'Please choose a course category!');
      return;
    }
    if (this.orgForm.invalid) {
      setTimeout(() => {
        document.querySelector('.ant-form-item-control.has-error').scrollIntoView();
      }, 60);
      this.message.create('warning', 'Please check your form！');
      return;
    }
    if (this.isSendingForm) return;
    this.isSendingForm = true;
    value.trialDays *= 1;
    this.http.post(this.urlsService.urls().org_create, value).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'successful');
        this.close();
        this.search();
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {
      this.isSendingForm = false;
    });
  }

  showImproveLimitModal(orgId:any) :void {
    this.ImproveLimit = 0;
    this.selectedOrgId = orgId;
    this.improveLimitModalVisible = true;
  }

  closeImproveLimitModal() :void {
    this.improveLimitModalVisible = false;
  }

  saveImproveLimit() :void {
    this.improveLimitModalVisible = false;
    if(this.ImproveLimit < -1) {
      this.closeImproveLimitModal();
      return;
    }
    console.log("orgId:",this.selectedOrgId,",limit:", this.ImproveLimit);
    const formData = new FormData();
    formData.append('orgId', this.selectedOrgId+'');
    formData.append('limit', this.ImproveLimit+'');
    this.http.post(this.urlsService.urls().org_add_limit_log, formData).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'successful');
        this.closeImproveLimitModal();
        this.search();
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {

    });
  }

  update(data: any): void {
    this.open();
    let updateFormData = Object.assign({}, data);
    this.orgForm.patchValue(updateFormData);
  }
  clear() {

  }
}
