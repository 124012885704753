import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd';
import {UrlsService} from "../services/urls.service";
import {HttpClient} from "@angular/common/http";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css'],
})
export class ClassComponent implements OnInit {
  orgList = [];
  selectedSchool = '';
  nzData = [];
  nzTotal = 0;
  nzPageIndex = 1;
  nzPageSize = 20;
  nzPageSizeOptions = [20, 30, 40, 50];
  nzLoading = false;

  drawerVisible = false;
  isSendingForm = false;

  isPreviewingForm = false;

  previewVisible = false;

  courses = [];

  classForm = this.fb.group({
    classId: [''],
    courseId:['', [Validators.required]],
    courseName:[''],
    status:[1, [Validators.required]],
    title: ['', [Validators.required,Validators.maxLength(60)]],
    description:[''],
  });


  constructor(private router: Router,
              private route: ActivatedRoute,
              private message: NzMessageService,
              private urlsService: UrlsService,
              private fb: FormBuilder,
              private http: HttpClient) {

  }
  ngOnInit() {
    this.search();
    this.getOrgList();
  }

  research() {
    this.search();
  }

  nzPageIndexChange(pageIndex: number) {
    this.nzPageIndex = pageIndex;
    this.search();
  }

  nzPageSizeChange(pageSize: number) {
    this.nzPageIndex = 1;
    this.nzPageSize = pageSize;
    this.search();
  }

  getCourses() {
    if(this.courses.length == 0)
    this.http.get<any>(this.urlsService.urls().get_courses + '?orgId=' + this.selectedSchool).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.courses = res.data;
        }
      }
    });
  }
  search(): void {
    this.nzLoading = true;
    this.http.get<any>(this.urlsService.urls().class_list  +
      `?orgId=` + this.selectedSchool + `&page=${this.nzPageIndex}&size=${this.nzPageSize}`).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.nzData = res.data.list;
          this.nzTotal = res.data.total;
        }
      }

      this.nzLoading = false;
    });
  }

  open() {
    this.classForm.reset();
    this.drawerVisible = true;
  }

  close() {
    this.drawerVisible = false;
  }
  submitForm($event: any, value: any): void {
    $event.preventDefault();
    value.status = 1;
    for (const key in this.classForm.controls) {
      this.classForm.controls[key].markAsDirty();
      this.classForm.controls[key].updateValueAndValidity();
    }
    if(value.classId == null) {
      value.classId = '';
    } else {
      value.classId += '';
    }

    value.courseId *= 1;
    value.courseName = this.getCourseName(value.courseId);

    if (this.isSendingForm) return;
    this.isSendingForm = true;
    this.http.post(this.urlsService.urls().class_create, value).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'successful');
        this.close();
        this.search();
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {
      this.isSendingForm = false;
    });
  }

  getCourseName(courseId:any):any {
    for(var i=0;i<this.courses.length;i++) {
      var course = this.courses[i];
      if(course["id"] == courseId) {
        return course["displayname"];
      }
    }
    return "";
  }

  update(data: any): void {
    this.open();
    let updateFormData = Object.assign({}, data);
    this.classForm.patchValue(updateFormData);
  }

  delete(data:any):void {
    if(!confirm('Confirm delete current class ?')) {
      return;
    }
    if (this.isSendingForm) return;
    this.isSendingForm = true;
    this.http.post(this.urlsService.urls().class_delete + '/' + data.classId, null).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'successful');
        this.close();
        this.search();
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `failure：${err}`);
    }, () => {
      this.isSendingForm = false;
    });
  }

  clear() {

  }

  orgChanged(orgId:any):void {
    this.search();
    this.getCourses();
  }

  getOrgList():void {
    this.http.get<any>(this.urlsService.urls().org_list).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.orgList = res.data.list;
        }
      }
    });
  }
}
