import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value:any, args?:any):any {
    switch (value) {
      case 'zh-Hans':
        return '简体';
      case 'zh-Hant':
        return '繁体';
      case 'en':
        return '英语';
      case 'el':
        return '希腊语';
    }
    return value;
  }

}
