import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from './user.service';
import {Statics} from "../global/statics";

@Injectable()
export class SystemNoSessionService implements CanActivate {
  constructor(private userService:UserService,
              private router:Router) {
  }

  canActivate():Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.userService.checkUserSession().subscribe(res => {
        if (res.errno === 200) {
          this.router.navigateByUrl(Statics.SESSION_PAGE);
          resolve(false);
        } else {
          resolve(true);
        }
      }, () => {
        resolve(true);
      });
    });
  }
}
