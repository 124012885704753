import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'awesomeDate'
})
export class AwesomeDatePipe implements PipeTransform {

  transform(value:any, args?:any):any {
    const date = new Date(value);
    const currentDate = new Date();
    const offset = currentDate.getTime() - date.getTime();
    const year = date.getFullYear();
    const month = this.getDoubleStr(date.getMonth() + 1);
    const dat = this.getDoubleStr(date.getDate());
    const hours = this.getDoubleStr(date.getHours());
    const minutes = this.getDoubleStr(date.getMinutes());

    if (offset > 3 * 60 * 60 * 1000) {
      return `${year}-${month}-${dat} ${hours}:${minutes}`;
    }

    if (offset > 2 * 60 * 60 * 1000) {
      return '2小时前';
    }

    if (offset > 1 * 60 * 60 * 1000) {
      return '1个小时前';
    }

    if (offset > 30 * 60 * 1000) {
      return '半小时前';
    }

    if (offset > 10 * 60 * 1000) {
      return '10分钟前';
    }

    if (offset > 5 * 60 * 1000) {
      return '5分钟前';
    }

    if (offset > 2 * 60 * 1000) {
      return '2分钟前';
    }

    if (offset > 1 * 60 * 1000) {
      return '1分钟前';
    }

    return '刚刚';
  }

  getDoubleStr(value) {
    if (value < 10) {
      return '0' + value;
    }
    return value + '';
  }

}
