import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from "../services/user.service";
import {UrlsService} from "../services/urls.service";
import {HttpClient} from "@angular/common/http";
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  constructor(private router: Router,
              private userService: UserService,
              private urlsService: UrlsService,
              private message: NzMessageService,
              private http: HttpClient,
  ) {
  }


  isCollapsed = false;
  triggerTemplate = null;
  isOpen = true;
  userCourses = [];
  drawerVisible = false;

  oldPassword = '';
  newPassword = '';

  @ViewChild('trigger') customTrigger: TemplateRef<void>;

  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  sub: any;
  url: string;

  ngOnInit() {
    if(typeof(this.userService.loginUser['userId']) == 'undefined') {
      this.router.navigateByUrl('/login');
      return;
    }
    this.sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        if(this.url == "/") {
          if(this.userService.loginUser != []) {
            this.http.get<any>(this.urlsService.urls().user_course_list).subscribe(res => {
              if (res.errno === 200) {
                if (res.data != null) {
                  this.userCourses = res.data;
                }
              }
            });
          }
        }
      }
    });
  }

  menuClick(url) {
    this.router.navigate([url]);
  }

  logout() {
    this.userService.logout().subscribe(res => {
        if (res.errno === 200) {
          this.router.navigateByUrl('/login');
        }}, (err) => {}
    )
  }

  changePwd() :void {
    console.log('old:'+this.oldPassword+',newPwd:'+this.newPassword);
    if(this.oldPassword == "" || this.newPassword == "") {

    }
    const formData = new FormData();
    formData.append('oldPassword', this.oldPassword);
    formData.append('newPassword', this.newPassword);
    this.http.post(this.urlsService.urls().user_change_pwd, formData).subscribe((res: any) => {
      if (res.errno === 200) {
        this.message.create('success', 'Successful');
        this.closeChangePwd();
      } else {
        this.message.create('error', `Failure：${res.msg}`);
      }
    }, (err) => {
      this.message.create('error', `Failure：${err}`);
    }, () => {
      this.oldPassword = "";
      this.newPassword = "";
    });
  }

  openChangePwd() {
    console.log('open change pwd');
    this.drawerVisible = true;
  }

  closeChangePwd() {
    this.drawerVisible = false;
  }

  jumpCourseDetail(courseId:any):void {
    this.autoLogin();
  }

  autoLogin():void {
    var loginForm = document.createElement('form');
    loginForm.method = 'post';
    loginForm.target = 'jumpFrame';
    loginForm.action = 'https://lms.centriesl.com/login/index.php';
    var username = document.createElement('input');
    username.type = 'hidden';
    username.name = 'username';
    username.value = this.userService.loginUser['username'];
    var password = document.createElement('input');
    password.type = 'hidden';
    password.name = 'password';
    password.value = this.userService.loginUser['mpassword'];
    loginForm.appendChild(username);
    loginForm.appendChild(password);
    console.log(loginForm);
    document.body.appendChild(loginForm);
    loginForm.submit();
    document.body.removeChild(loginForm);
  }

}
