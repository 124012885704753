import {isPlatformBrowser} from '@angular/common';
import {PLATFORM_ID, Inject} from '@angular/core';

export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  setRefreshTime(refreshTime: number) {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem('refreshTime', JSON.stringify(refreshTime));
    }
  }

  getRefreshTime() {
    if (isPlatformBrowser(this.platformId)) {
      const refreshTime = window.localStorage.getItem('refreshTime');
      if (window.localStorage && refreshTime != null) {
        return JSON.parse(window.localStorage.getItem('refreshTime'));
      }
    }

    return 0;
  }

  clearRefreshTime() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.removeItem('refreshTime');
    }
  }

  setProductTrade(productTrad) {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem('productTrad', JSON.stringify(productTrad));
    }
  }

  getProductTrad() {
    if (isPlatformBrowser(this.platformId)) {
      const productTrad = window.localStorage.getItem('productTrad');
      if (window.localStorage && productTrad != null) {
        return JSON.parse(window.localStorage.getItem('productTrad'));
      }
    }

    return [];
  }

  clearProductTrad() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.removeItem('productTrad');
    }
  }

  setProductTitleMap(productTitleMap) {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem('productTitleMap', JSON.stringify(productTitleMap));
    }
  }

  getProductTitleMap() {
    if (isPlatformBrowser(this.platformId)) {
      const productTitleMap = window.localStorage.getItem('productTitleMap');
      if (window.localStorage && productTitleMap != null) {
        return JSON.parse(window.localStorage.getItem('productTitleMap'));
      }
    }

    return [];
  }

  clearProductTitleMap() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.removeItem('productTitleMap');
    }
  }

}
