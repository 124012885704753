import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { SystemNoSessionService } from "./services/system-nosession.service";
import { IndexComponent } from "./index/index.component";
import { SystemSessionService } from "./services/system-session.service";
import {OrgComponent} from "./org/org.component";
import {ClassComponent} from "./class/class.component";
import {UserComponent} from "./user/user.component";
import {MemberComponent} from "./member/member.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [SystemNoSessionService] },
  { path: 'index', component: IndexComponent, canActivate: [SystemSessionService] },
  { path: 'org', component: OrgComponent, canActivate: [SystemSessionService] },
  { path: 'class', component: ClassComponent, canActivate: [SystemSessionService] },
  { path: 'user', component: UserComponent, canActivate: [SystemSessionService] },
  { path: 'user/:classId/list', component: MemberComponent, canActivate: [SystemSessionService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })]
})
export class AppRoutingModule {
}
