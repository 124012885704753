import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { LayoutComponent } from './layout/layout.component';
import { AwesomeDatePipe } from "./pipes/awesome-date.pipe";
import { LocaleDatePipe } from "./pipes/locale-date.pipe";
import { StorageService } from "./services/local-storage.service";
import { SystemSessionService } from "./services/system-session.service";
import { SystemNoSessionService } from "./services/system-nosession.service";
import { LoginComponent } from "./login/login.component";
import { RouterModule } from "@angular/router";
import { IndexComponent } from './index/index.component';
import { UserService } from "./services/user.service";
import { UrlsService } from "./services/urls.service";
import { CkEditorComponent } from './ck-editor/ck-editor.component';
import { LanguagePipe } from "./pipes/language.pipe";
import {OrgComponent} from "./org/org.component";
import {ClassComponent} from "./class/class.component";
import {UserComponent} from "./user/user.component";
import {MemberComponent} from "./member/member.component";

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AwesomeDatePipe,
    LocaleDatePipe,
    LanguagePipe,
    LoginComponent,
    IndexComponent,
    OrgComponent,
    ClassComponent,
    UserComponent,
    MemberComponent,
    CkEditorComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    NgZorroAntdModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    UserService,
    UrlsService,
    StorageService,
    SystemSessionService,
    SystemNoSessionService,
    { provide: NZ_I18N, useValue: zh_CN }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
