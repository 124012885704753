import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = new Date(parseInt(value));
    const currentDate = new Date();
    const offset = currentDate.getTime() - date.getTime();
    const year = date.getFullYear();
    const month = this.getDoubleStr(date.getMonth() + 1);
    const dat = this.getDoubleStr(date.getDate());
    const hours = this.getDoubleStr(date.getHours());
    const minutes = this.getDoubleStr(date.getMinutes());

    return `${year}-${month}-${dat} ${hours}:${minutes}`;
  }
  getDoubleStr(value) {
    if (value < 10) {
      return '0' + value;
    }
    return value + '';
  }
}
