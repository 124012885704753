import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";


@Injectable()
export class UrlsService {

  constructor() {
  }

  urls() {
    const prod = {
      'system_s2t': '/system/s2t',
      'system_t2s': '/system/t2s',
      'user_login': '/user/login',
      'user_logout': '/user/logout',
      'user_checksession': '/user/checksession',
      'user_change_pwd': '/user/change_pwd',
      'file_upload': '/file/upload',
      'file_download': '/file/download',
      'org_list': '/org/list',
      'org_create': '/org/create',
      "org_add_limit_log": '/org/add_limit_log',
      'class_list': '/class/list',
      'get_courses': '/class/get_courses',
      'course_categories':'/course/categories',
      'class_create': '/class/create',
      'class_delete': '/class/delete',
      'user_list': '/user/list',
      'user_create': '/user/create',
      'user_delete': '/user/delete',
      'user_change': '/user/change',
      'user_course_list':'/user/courses',
      'member_remove':'/class/member/remove',
      'member_add':'/class/member/add',
      'upload_user_file':'/file/upload_user_file',
    };

    for (var i in prod) {
      prod[i] = `/api${prod[i]}`
    }

    return prod;
  }
}
