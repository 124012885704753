import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd";
import {StorageService} from "../services/local-storage.service";
import {UrlsService} from "../services/urls.service";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {


  constructor(private router: Router,
              private route: ActivatedRoute,
              private message: NzMessageService,
              private localStorage: StorageService,
              private urlsService: UrlsService,
              private userService: UserService,
              private http: HttpClient) {

  }
  userCourses = [];
  ngOnInit() {
    this.http.get<any>(this.urlsService.urls().user_course_list).subscribe(res => {
      if (res.errno === 200) {
        if (res.data != null) {
          this.userCourses = res.data;
        }
      }

    });
  }

  jumpCourseDetail(courseId:any):void {
    this.autoLogin();
    return;
    const formData = new FormData();
    formData.append('username', "jack");
    formData.append('password', "LiChaoGang123!@#");
    this.http.post('https://lms.centriesl.com/login/index.php', formData).subscribe((res: any) => {
      if (res.errno === 200) {
        console.log(res);
      } else {
        this.message.create('error', `failure：${res.msg}`);
      }
    });
  }

  autoLogin():void {
    var loginForm = document.createElement('form');
    loginForm.method = 'post';
    loginForm.target = 'jumpFrame';
    loginForm.action = 'https://lms.centriesl.com/login/index.php';
    var username = document.createElement('input');
    username.type = 'hidden';
    username.name = 'username';
    username.value = this.userService.loginUser['username'];
    var password = document.createElement('input');
    password.type = 'hidden';
    password.name = 'password';
    password.value = this.userService.loginUser['mpassword'];
    loginForm.appendChild(username);
    loginForm.appendChild(password);
    console.log(loginForm);
    document.body.appendChild(loginForm);
    loginForm.submit();
    document.body.removeChild(loginForm);
  }
}
