import {Component, OnInit} from '@angular/core';
import {NzMessageService} from "ng-zorro-antd";
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;

  constructor(private fb: FormBuilder,
              private router: Router,
              private message: NzMessageService,
              private userService: UserService) {
  }


  submitForm(): void {

    const formData = this.validateForm.value;

    this.validateForm.get("userName")
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    this.userService.login(formData.userName, formData.password).subscribe(res => {
      if (res.errno === 200) {
        this.userService.loginUser = res.data;
        location.href = location.href.split('#')[0];
      } else {
        this.message.error(res.msg);
      }
    });
  }


  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

}
