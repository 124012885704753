import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.css'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CkEditorComponent),
    }
  ]
})
export class CkEditorComponent implements ControlValueAccessor {

  Editor = DecoupledEditor;
  onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
  }

  config = {
      placeholder: '请在这里输入...',
      toolbar: [ 'heading', '|', 'bold', 'italic', 'underline', '|', 'alignment', '|', 'numberedList', 'bulletedList', '|', 'link', 'blockQuote', 'insertTable', '|', 'undo', 'redo']
  }

  @Input('placeholder')
  set placeholder(_placeholder) {
    this.config.placeholder = _placeholder;
  }

  @Input('value')
  val: string;

  // Both onChange and onTouched are functions
  onChange: any = () => { 
  };
  onTouched: any = () => { 
  };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  onDataChange({ editor }: ChangeEvent) {
    if (editor) {
      this.value = editor.getData();
    } else {
      alert('编辑器未知错误，请联系开发人员');
    }
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  //callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    this.value = value;
  }

}
